$out-of-range-color: lighten(#333, 40%) !default;
$out-of-range-bg-color: lighten(#333, 70%) !default;

$calendar-border: #ddd !default;
$cell-border: #ddd !default;

$border-color: #ccc !default;

// Each calendar segment is 1/7th.
$segment-width: 0.14286% !default;

$time-selection-color: white !default;
$time-selection-bg-color: rgba(0, 0, 0, 0.5) !default;
$date-selection-bg-color: rgba(0, 0, 0, 0.1) !default;

.rbc-event {
  @apply bg-secondary-500/90 dark:bg-secondary-700/80;
  @apply border border-secondary-700 dark:border-secondary-900;
}
//$event-bg: #31ad77 !default;
//$event-border: darken(#2a9567, 10%) !default;
$event-outline: #31ad77 !default;
$event-color: #fff !default;
$event-border-radius: 5px !default;
$event-padding: 2px 5px !default;
$event-zindex: 4 !default;

$btn-color: #373a3c !default;
$btn-bg: #fff !default;
$btn-border: #ccc !default;

$current-time-color: #8f1b85 !default;

$rbc-css-prefix: rbc-i !default;

$today-highlight-bg: #eaf6ff !default;

.rbc-timeslot-group {
  min-height: 60px;
}

.rbc-today-dark {
  background-color: #bff9eb4d;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0px;
}

.rbc-event-label {
  display: none;
}
.rbc-day-slot .rbc-event-content {
  padding-top: 0.25rem;
  text-align: center;
}

.rbc-today {
  @apply bg-neutral-300/60 dark:bg-neutral-500/50 backdrop-blur-2xl backdrop-filter;
}
.rbc-toolbar button {
  @apply bg-neutral-500/30 dark:bg-neutral-700/80;
  @apply text-neutral-900 dark:text-neutral-100;
}
// .rbc-time-view {
//   @apply dark:border-neutral-700;
// }
