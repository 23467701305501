.glassmorphism {
  background: rgba(
    255,
    255,
    255,
    0.2
  ); /* Adjust the color and opacity as needed */
  backdrop-filter: blur(10px); /* The blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: border for more effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow for depth */
}

.dark .glassmorphism {
  background: rgba(0, 0, 0, 0.2); /* Adjust the color and opacity as needed */
  backdrop-filter: blur(10px); /* The blur effect */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Optional: border for more effect */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow for depth */
}
